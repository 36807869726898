var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "appliedList" },
    [
      _c(
        "div",
        { staticClass: "search-box" },
        [
          _c("i-header", {
            attrs: { title: "申请名单", showBack: true },
            on: { "back-event": _vm.toBack },
          }),
          _c("i-search", {
            attrs: {
              shape: "round",
              clearable: "",
              "clear-trigger": "always",
              placeholder: "请输入姓名/拼音/邮箱/员工号",
            },
            on: { input: _vm.onSearch },
            model: {
              value: _vm.searchKey,
              callback: function ($$v) {
                _vm.searchKey = $$v
              },
              expression: "searchKey",
            },
          }),
        ],
        1
      ),
      _vm.list.length
        ? _c(
            "i-list",
            {
              attrs: {
                offset: 1,
                finished: _vm.finished,
                "finished-text": "没有更多了",
                "immediate-check": false,
              },
              on: { load: _vm.nextPage },
              model: {
                value: _vm.loading,
                callback: function ($$v) {
                  _vm.loading = $$v
                },
                expression: "loading",
              },
            },
            _vm._l(_vm.list, function (item, index) {
              return _c("div", { key: index, staticClass: "item" }, [
                _c("div", { staticClass: "left" }, [
                  _c("div", { staticClass: "title" }, [
                    _c("div", { staticClass: "title-name" }, [
                      _vm._v(_vm._s(item.name) + " "),
                      _c("div", { staticClass: "sub_title" }, [
                        _vm._v(_vm._s(item.extId)),
                      ]),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "title-sign",
                        style: {
                          color:
                            item.status == 1
                              ? "#a67c0b"
                              : item.status == 0
                              ? "#4AC672"
                              : "#909399",
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              item.status == 1
                                ? "待授权"
                                : item.status == 0
                                ? "已同意"
                                : "已拒绝"
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "desc",
                      staticStyle: { "margin-top": "0.08rem" },
                    },
                    [
                      _c("i-icon", {
                        attrs: {
                          size: "14",
                          color: "#13161B",
                          name: "icon-qita",
                        },
                      }),
                      _vm._v(_vm._s(item.group6 || "-")),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "desc" },
                    [
                      _c("i-icon", {
                        attrs: {
                          size: "14",
                          color: "#13161B",
                          name: "icon-youxiang",
                        },
                      }),
                      _vm._v(_vm._s(item.email || "（暂无）")),
                    ],
                    1
                  ),
                ]),
                item.status === 1
                  ? _c(
                      "div",
                      { staticClass: "right" },
                      [
                        _c(
                          "i-button",
                          {
                            nativeOn: {
                              click: function ($event) {
                                return _vm.handle("拒绝", item)
                              },
                            },
                          },
                          [_vm._v("拒绝")]
                        ),
                        _c(
                          "i-button",
                          {
                            attrs: { type: "primary" },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.handle("同意", item)
                              },
                            },
                          },
                          [_vm._v("通过")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ])
            }),
            0
          )
        : _vm._e(),
      !_vm.list.length ? _c("empty-box") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div class="appliedList">
        <div class="search-box">
            <i-header title="申请名单" :showBack="true" @back-event="toBack">
            </i-header>
            <i-search v-model="searchKey"
                      shape="round"
                      clearable
                      clear-trigger="always"
                      @input="onSearch" placeholder="请输入姓名/拼音/邮箱/员工号"></i-search>
        </div>
        <i-list v-if="list.length" v-model="loading" :offset="1" :finished="finished" finished-text="没有更多了"
                :immediate-check="false"
                @load="nextPage">
            <div class="item" v-for="(item,index) in list" :key="index">
                <div class="left">
                    <div class="title">
                        <div class="title-name">{{ item.name }}
                        <div class="sub_title">{{item.extId}}</div></div>
                        <div class="title-sign"
                             :style="{color: item.status == 1 ? '#a67c0b' : item.status == 0 ? '#4AC672' : '#909399'}">
                            {{ item.status == 1 ? '待授权' : item.status == 0 ? '已同意' : '已拒绝' }}
                        </div>
                    </div>
                    <div class="desc" style="margin-top: 0.08rem"> <i-icon size="14" color="#13161B" name="icon-qita" />{{ item.group6 || '-' }}</div>
                    <div class="desc"><i-icon size="14" color="#13161B" name="icon-youxiang"/>{{ item.email || '（暂无）' }}</div>
                </div>
                <div v-if="item.status === 1" class="right">
                    <i-button @click.native="handle('拒绝', item)">拒绝</i-button>
                    <i-button type="primary" @click.native="handle('同意', item)">通过</i-button>
                </div>
            </div>
        </i-list>
        <empty-box v-if="!list.length" />
    </div>
</template>

<script>
import {debounce} from "lodash";
import {Dialog} from 'vant'
import emptyBox from "@/components/iEmpty";
export default {
    name: "index",
    components: {
        emptyBox
    },
    data() {
        return {
            searchKey: '',
            list: [],
            loading: false,
            finished: false,
            pageIndex: 1,
            miceId: ''
        }
    },
    methods: {
        onSearch: debounce(function () {
            this.list = [];
            this.pageIndex = 1;
            this.getList();
        }, 800),
        handle(val, item) {
            this.$idialog.confirm({
                title: '提示',
                message: `是否确认${val}授权?`,
            }).then(() => {
                let params = {
                    Status: val === '拒绝' ? 2 : 0,
                    AgentId: item.agentId,
                    MiceId: this.miceId,
                    UserId: item.userId
                }
                this.$service.updateEmployeeApply(params).then((res) => {
                    if (res.success) {
                        this.$itoast.success('成功');
                        this.list = [];
                        this.pageIndex = 1;
                        this.getList();
                    } else {
                        this.$itoast.fail(`${val}失败，请稍后继续`);
                    }
                })
            })
        },
        nextPage() {
            this.pageIndex++;
            this.getList();
        },
        toBack() {
            this.$router.go(-1)
        },
        getList() {
            this.loading = true;
            const params = {
                "flag": 1,
                "AgentType": 2,
                "Marker": this.searchKey,
                "Status": 0,
                "pageIndex": this.pageIndex,
                "pageSize": 10,
                "miceId": this.miceId
            }
            this.$service.getAppliedList(params).then(res => {
                if (res.success && res.content.rows.length) {
                    this.list = [...this.list, ...res.content.rows];
                    this.loading = false;
                    this.finished = res.content.rows.length < 10;
                } else {
                    this.loading = false;
                    this.finished = false;
                }
            })
        },
    },
    mounted() {
        this.miceId = this.$route.query.miceId;
        this.getList();
        // this.$service.getAuthorizationList({agentType: 2, miceId: this.miceId, rnd: new Date().getTime()}).then(res => {
        //     console.log(res);
        // })
    }
}
</script>

<style scoped lang="less">
.van-list {
    height: calc(100vh - 0.74rem);
    overflow-y: auto;
    //padding-bottom: 0.5rem;
    box-sizing: border-box;
}

.search-box {
    //display: flex;
    //align-items: center;
    //width: 100vw;
    //padding: 0.2rem 0.18rem;
    //box-sizing: border-box;

    .back-icon {
        height: 0.3rem;
        display: flex;
        align-items: center;
        margin-right: 0.1rem;
        flex-shrink: 0
    }

    /deep/ .van-search {
        margin: 0rem 0.12rem;
        box-sizing: border-box;
        //width: 100%;
        padding: unset;
        background: #F5F5F5;
        border-radius: 0.04rem;

        .van-search__content {
            height: 0.3rem;
            background: #F5F5F5;
            //box-shadow: 0px 0.02rem 0.13rem 0px rgba(35, 24, 21, 0.1);
            display: flex;
            align-items: center;
            font-size: 0.14rem;

            .van-cell {
                height: 0.3rem;
                box-sizing: border-box;
                display: flex;
                align-items: center;
            }

            .van-field__control {
                height: 0.3rem;
            }

            .van-field__control::placeholder {
                color: #909399;
                -webkit-text-fill-color: #909399 !important;
            }
        }
    }

    /deep/ .van-icon-search {
        color: #c0c4cc;
    }
}

.van-list{
    padding: 0 0.12rem;
    .item{
        border-bottom: 0.01rem solid #F5F5F5;
        padding: 0.14rem 0;
        .left{
            .title{
                display: flex;
                align-items: center;
                justify-content: space-between;
                .title-name{
                    font-size: 0.16rem;
                    font-weight: bold;
                    color: #13161B;
                    display: flex;
                    align-items: flex-end;
                    justify-content: flex-start;
                    .sub_title{
                        font-size: 0.12rem;
                        font-weight: lighter;
                        color: #909399;
                        margin-left: 0.1rem;
                    }
                }
            }
        }
        &:nth-last-child(3){
            border-bottom: none;
        }

        .desc{
            font-size: 0.14rem;
            color: #13161B;
            font-weight: normal;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            svg{
                margin-right: 0.06rem;
            }
        }
        .right{
            text-align: right;
            .van-button{
                height: 0.32rem!important;
                color: #909399;
            }
            .van-button--primary{
                color: #fff;
                margin-left: 0.08rem;
            }
        }
    }
}

</style>
